import React from 'react';
import ReactDOM from 'react-dom/client';
import Lottie from "react-lottie";
import animationData from "./lottie/Socials_lottie.json";
import './css/all.css';
import './css/bootstrap.min.css';
import './css/fontawesome.min.css';
import './css/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

root.render(
    <div className="main-wrapper">
    {/*Navbar*/}
    <meta charSet="UTF-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Weme</title>
    <link rel="stylesheet" href="css/all.css" />
    <link rel="stylesheet" href="css/bootstrap.min.css" />
    <link rel="stylesheet" href="css/fontawesome.min.css" />
    <link rel="stylesheet" href="css/style.css" />
    <section id="navbar">
    <nav className="navbar navbar-expand-lg">
    <div className="container-fluid">
    <a className="navbar-brand" href="#"><img src="images/logo.png" /></a>
    <div className="navbar-nav align-items-center flex-row">
    <a className="nav-link signin" aria-current="page" href="#">Sign in</a>
    <a className="nav-link signup btnhover" href="#">Sign up free</a>
</div>
</div>
</nav>
</section>
{/*main*/}
<section id="main">
    <div className="container">
    <div className="row justify-content-center">
    <div className="col-lg-10 text-center">
    <h1 className="heading">A link in bio that turn cannabis followers into customers</h1>
<p>Simply add your products to Weme and transform your social media bio into a social storefront all powered by Weed Messenger.</p>
<div className="main-text position-relative">
    <div className="input-group align-items-center">
    <span>weme.bio/</span>
    <input type="text" className="form-control" placeholder="your name" aria-label="your name" aria-describedby="button-addon2" />
    <a className="signup" type="button" id="button-addon2">Generate</a>
    </div>
    <div className="main-frame">
    <img src="images/frame.png" className="img-fluid" />
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className="link-group">
    <nav id="links">
    <ul className="d-flex nav">
    <li className="nav-item tabs"><a className="nav-link" href="#socialMedia">Social media Integration</a></li>
<li className="icon">📚</li>
<li className="nav-item tabs"><a className="nav-link" href="#Educational">Educational materials</a></li>
<li className="icon">☘️</li>
<li className="nav-item tabs"><a className="nav-link" href="#CannabisPathways">Cannabis Pathways</a></li>
<li className="icon">📬</li>
<li className="nav-item tabs"><a className="nav-link" href="#LiveChat">Live chat with expert</a></li>
<li className="icon">🚛</li>
<li className="nav-item tabs"><a className="nav-link" href="#Events">Events</a></li>
<li className="icon">🪄</li>
<li className="nav-item tabs"><a className="nav-link" href="#landingPage">Customizable landing page</a></li>
</ul>
</nav>
</div>
</section>
{/*Video*/}
<section id="video">
    <div data-bs-spy="scroll" data-bs-target="#links" data-bs-smooth-scroll="true" data-bs-root-margin="0px 0px 0px 0px" data-bs-offset={0} tabIndex={0}>
    <div className="container-xl video-contanier">
    <div className="row align-items-center" id="landingPage">
    <div className="col-lg-5">
    <div className="video">
        <div style={{
                backgroundColor: "transparent",
                width: "100%"
            }}>
            <Lottie options={defaultOptions} style={{width: '100%', height: 'auto'}}/>
        </div>
    {/*<lottie-player src="json/event.json" className="animation-lottie" background="#F4F4F0" speed={1} style={{width: '100%', height: 'auto'}} loop autoPlay />*/}
{/* <video src="images/Customize.webm" autoplay="" loop="" muted="" playsinline="" style="width: 100%;"></video> */}
</div>
</div>
<div className="col-lg-7">
    <div className="video-text">
    <div>
    <h1>Customizable website</h1>
<p>Personalize your website by editing its color scheme, logo, content to match your branding. It's easy to use and allows you to create a unique and engaging page that aligns with your marketing goals.</p>
</div>
<div className="buton">
    <a className="signup btnhover" href="timer.html">Sign up free</a>
</div>
</div>
</div>
</div>
<div className="row align-items-center row1" id="socialMedia">
    <div className="col-lg-5 order-lg-last">
    <div className="video">
    <video src="images/SocialMedias.webm" autoPlay loop muted playsInline style={{width: '100%'}} />
</div>
</div>
<div className="col-lg-7">
    <div className="video-text">
    <div>
    <h1>Social Media Integration</h1>
<p>Seamlessly integrate with your social media profiles, including Facebook, Twitter, Instagram, and more. Embed social media feeds to encourage visitors to connect with you on social media and increase your online presence.
</p>
</div>
<div className="buton">
    <a className="signup btnhover" href="timer.html">Sign up free</a>
</div>
</div>
</div>
</div>
<div className="row align-items-center row1" id="Educational">
    <div className="col-lg-5">
    <div className="video">
    <video src="images/Education.webm" autoPlay loop muted playsInline style={{width: '100%'}} />
</div>
</div>
<div className="col-lg-7">
    <div className="video-text">
    <div>
    <h1>Expand cannabis knowledge</h1>
<p>Empower your user with cannabis education! Explore our comprehensive materials on consumption, strains, and effects. Get reliable information to make informed decisions. Discover the world of cannabis with us!
</p>
</div>
<div className="buton">
    <a className="signup btnhover" href="timer.html">Sign up free</a>
</div>
</div>
</div>
</div>
<div className="row align-items-center row1" id="CannabisPathways">
    <div className="col-lg-5 order-lg-last">
    <div className="video">
    <video src="images/CannabisPathways.webm" autoPlay loop muted playsInline style={{width: '100%'}} />
</div>
</div>
<div className="col-lg-7">
    <div className="video-text">
    <div>
    <h1>Cannabis pathways</h1>
<p>Shop smarter and find your perfect cannabis product faster with the Cannabis Pathway. Say goodbye to guesswork and confidently make informed decisions for a personalized cannabis shopping journey.
</p>
</div>
<div className="buton">
    <a className="signup btnhover" href="timer.html">Sign up free</a>
</div>
</div>
</div>
</div>
<div className="row align-items-center row1" id="LiveChat">
    <div className="col-lg-5">
    <div className="video">
    <video src="images/LiveChat.webm" autoPlay loop muted playsInline style={{width: '100%'}} />
</div>
</div>
<div className="col-lg-7">
    <div className="video-text">
    <div>
    <h1>Real-Time Guidance</h1>
<p>Real-time cannabis expertise at your fingertips! Chat with our experts for personalized guidance on strains, dosages, consumption methods, and more.
</p>
</div>
<div className="buton">
    <a className="signup btnhover" href="timer.html">Sign up free</a>
</div>
</div>
</div>
</div>
<div className="row align-items-center row1" id="Events">
    <div className="col-lg-5 order-lg-last">
    <div className="video">
    <video src="images/Events.webm" autoPlay loop muted playsInline style={{width: '100%'}} />
</div>
</div>
<div className="col-lg-7">
    <div className="video-text">
    <div>
    <h1>Light Up Your Event</h1>
<p>Discover and register for the best cannabis events with ease! Dispensaries can create and promote their events, while users can register effortlessly through our website. Stay updated and connected with the latest cannabis
happenings in your area.
</p>
</div>
<div className="buton">
    <a className="signup btnhover" href="timer.html">Sign up free</a>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
{/*Contact*/}
<section id="contact">
    <div className="container">
    <div className="row">
    <div className="col-lg-12">
    <div className="contactBox">
    <h1>Contact us</h1>
<form className="row g-3">
    <div className="col-lg-6">
    <label htmlFor="name" className="form-label">Business Name</label>
<input type="text" className="form-control" id="name" placeholder="Name" />
    </div>
    <div className="col-lg-6">
    <label htmlFor="inputemail" className="form-label">Business E-mail</label>
<input type="email" className="form-control" id="inputemail" placeholder="E-mail" />
    </div>
    <div className="col-lg-12 message">
    <label htmlFor="inputAddress" className="form-label">Message</label>
    <textarea className="form-control" placeholder="Message" id="floatingTextarea" defaultValue={""} />
</div>
<div className="col-lg-12 forn-button">
    <a className="signup1 btnhover" href="#">Sign up free</a>
</div>
</form>
</div>
</div>
</div>
</div>
</section>
{/*Footer*/}
<section id="footer">
    <div className="container">
    <div className="row">
    <div className="col-lg-12 footerBox">
    <img src="images/logo.png" className="img-fluid" />
    <p>Powered by WeedMessengre</p>
</div>
</div>
</div>
</section>
</div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
